export enum UserRoleEnum {
  admin = "admin",
  manager = "manager",
  user = "user",
}

export const UserRoleNameEnum: Record<UserRoleEnum, string> = {
  [UserRoleEnum.admin]: "Admin",
  [UserRoleEnum.user]: "User",
  [UserRoleEnum.manager]: "Content creator",
};
