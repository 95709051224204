export enum CourseStatusEnum {
  published = "published",
  draft = "draft",
  inReview = "in-review",
  new = "new",
}

export const CourseStatusNameEnum: Record<CourseStatusEnum, string> = {
  [CourseStatusEnum.published]: "Published",
  [CourseStatusEnum.draft]: "Draft",
  [CourseStatusEnum.inReview]: "In review",
  [CourseStatusEnum.new]: "New",
};
